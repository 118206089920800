<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" class="pt-0">
        <v-card class="elevation-0">
          <v-card-title class="pa-4">
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <search-field @search="search" :title="'Search'"></search-field>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="3"
                lg="3"
                xl="3"
                offset-md="5"
                offset-lg="5"
                offset-xl="5"
                order="first"
                order-sm="first"
                order-md="last"
                order-lg="last"
                order-xl="last"
              >
                <div class="float-right">
                  <fab-button
                    @click="openHideVehiclesFromCompanyDialog()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-truck-remove'"
                    :tooltipSpan="'Hide Vehicles From Company'"
                  ></fab-button
                  >&nbsp;
                  <fab-button
                    v-if="$moduleAccess('add')"
                    @click="openAddDialog()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-plus'"
                    :tooltipSpan="'Add'"
                  ></fab-button
                  >&nbsp;
                  <fab-button
                    @click="getData()"
                    dark
                    top
                    xsmall
                    :icon="'mdi-refresh'"
                    :tooltipSpan="'Refresh'"
                  ></fab-button>
                </div>
              </v-col>
            </v-row>
          </v-card-title>
        </v-card>
        <v-divider></v-divider>
        <v-card class="elevation-0">
          <v-container fluid>
            <v-row no-gutters>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-row>
                  <v-col cols="12" class="py-2">
                    <!-- <v-btn text @click="openFilterExpansion">
                      <v-icon>{{ openFilter ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      <span>&nbsp;&nbsp;Filter {{ title }}</span>
                    </v-btn>-->
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="6"
                offset-md="2"
                lg="6"
                offset-lg="2"
                xl="6"
                offset-xl="2"
              >
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="9"
                    offset-md="3"
                    lg="9"
                    offset-lg="3"
                    xl="9"
                    offset-xl="3"
                    class="py-2"
                  >
                    <div
                      class="float-right d-none d-sm-flex d-sm-none d-md-flex"
                    >
                      <p
                        class="text-xs-right grey--text caption my-2 mx-2"
                        style="font-size: 14px; margin-top: 14px; display: inline-block;"
                      >
                        Row per Page:
                      </p>
                      <v-select
                        style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                        :items="page"
                        class="grey--text mx-2"
                        v-model="pageLimit"
                        @input="limitClick"
                        dense
                        height="20px"
                        :disabled="this.items.length < 10 ? true : false"
                      ></v-select>
                      <p
                        class="text-xs-center grey--text caption my-2 mx-1"
                        style="font-size: 14px;display: inline-block;"
                      >
                        {{ itemFrom + " -" + itemTo + " of " }} {{ totalItems }}
                      </p>

                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="backClick"
                        :disabled="disabledBack"
                      >
                        <v-icon dark>mdi-chevron-left</v-icon>
                      </v-btn>
                      <v-btn
                        style="display: inline-block; height: 34px;"
                        fab
                        light
                        small
                        text
                        @click="nextClick"
                        :disabled="disabledNext"
                      >
                        <v-icon dark>mdi-chevron-right</v-icon>
                      </v-btn>
                    </div>
                    <v-row
                      no-gutters
                      class="d-flex d-sm-none d-none d-sm-flex d-md-none"
                    >
                      <v-col cols="12" sm="12">
                        <p
                          class="text-xs-right grey--text caption my-2 mx-2"
                          style="font-size: 14px; margin-top: 14px; display: inline-block;"
                        >
                          Row per Page:
                        </p>
                        <v-select
                          style="font-size: 14px; max-width: 60px; display: inline-block; height: 34px;"
                          :items="page"
                          class="grey--text mx-2"
                          v-model="pageLimit"
                          @input="limitClick"
                          dense
                          height="20px"
                          :disabled="this.items.length < 10 ? true : false"
                        ></v-select>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-left float-sm-left">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="backClick"
                            :disabled="disabledBack"
                          >
                            <v-icon dark>mdi-chevron-left</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                      <v-col cols="8" sm="8" style="text-align: center;">
                        <p
                          class="text-xs-center grey--text caption my-2 mx-1"
                          style="font-size: 14px; display: inline-block;"
                        >
                          {{ itemFrom + " -" + itemTo + " of " }}
                          {{ totalItems }}
                        </p>
                      </v-col>
                      <v-col cols="2" sm="2">
                        <div class="float-right float-sm-right">
                          <v-btn
                            style="display: inline-block; height: 34px;"
                            fab
                            light
                            small
                            text
                            @click="nextClick"
                            :disabled="disabledNext"
                          >
                            <v-icon dark>mdi-chevron-right</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          disable-pagination
          hide-default-footer
          :server-items-length="items.length"
        >
          <template v-if="items.length === 0" v-slot:no-data>
            <v-container>
              <v-row>
                <v-col md="6" offset-md="3">
                  <span class="title font-weight-bold">NO DATA AVAILABLE.</span>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td class="text-center">
                  {{ index + queryParams.offset + 1 }}
                </td>
                <td class="text-uppercase">{{ item.plate_no }}</td>
                <td class="text-uppercase">{{ item.vehicle_no }}</td>
                <td class="text-uppercase">
                  {{ item.gps_device ? item.gps_device.imei_no : "--" }}
                </td>
                <td class="text-center text-no-wrap">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="blue"
                        icon
                        @click="openViewDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>View</span>
                  </v-tooltip>
                  <v-tooltip top v-if="$moduleAccess('edit')">
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="teal"
                        icon
                        @click="openEditDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Edit</span>
                  </v-tooltip>
                  <v-tooltip v-if="$moduleAccess('delete')" top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="red"
                        icon
                        @click="openDeleteDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Delete</span>
                  </v-tooltip>
                  <!-- <v-tooltip v-if="$moduleAccess('settings')" top> -->
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="blue-grey darken-2"
                        icon
                        @click="openStatusDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-cog</v-icon>
                      </v-btn>
                    </template>
                    <span>Vehicle Status Settings</span>
                  </v-tooltip>
                  <v-tooltip
                    top
                    v-if="!item.gps_device && $moduleAccess('assign gps')"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn
                        color="green lighten-1"
                        icon
                        @click="openAssignGPSDialog(item)"
                        v-on="on"
                      >
                        <v-icon>mdi-crosshairs-gps</v-icon>
                      </v-btn>
                    </template>
                    <span>Assign GPS</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <!---- Here is the Add, View, Edit Vehicles Dialog ----->

    <full-width-dialog
      :dialog="fullWidthDialog"
      :title="dialogType + ' ' + title"
      :color="
        dialogType === 'Add'
          ? 'amber'
          : dialogType === 'View'
          ? 'blue'
          : dialogType === 'Edit'
          ? 'teal'
          : ''
      "
      :dialogLoading="fullWidthDialogLoading"
      :dialogLoadingColor="
        dialogType === 'Add'
          ? 'amber'
          : dialogType === 'View'
          ? 'blue'
          : dialogType === 'Edit'
          ? 'teal'
          : ''
      "
      :saveButton="saveButton"
      dark
      @saveDialog="
        dialogType === 'Add'
          ? validateAddDialog()
          : dialogType === 'Edit'
          ? validateEditDialog()
          : ''
      "
      @closeDialog="
        dialogType === 'Add'
          ? closeAddDialog()
          : dialogType === 'View'
          ? closeViewDialog()
          : dialogType === 'Edit'
          ? closeEditDialog()
          : ''
      "
    >
      <v-container fluid>
        <v-row>
          <!-- <v-col cols="12" sm="12" md="4" lg="4" xl="4"></v-col> -->
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <details-container
              :data="data"
              :gpsDevices="gpsDevices"
              :readonly="readonly"
              :dialogType="dialogType"
              ref="detailsContainer"
              @openImages="openImages()"
              @openSaveDialog="
                dialogType === 'Add'
                  ? openSaveAddDialog()
                  : dialogType === 'Edit'
                  ? openSaveEditDialog()
                  : ''
              "
            ></details-container>
          </v-col>
          <!-- <v-col cols="12" sm="12" md="4" lg="4" xl="4"></v-col> -->
        </v-row>
      </v-container>
    </full-width-dialog>

    <!---- Here is the Confirmation of Add Vehicle ---->

    <mini-dialog
      :dialog="saveAddDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeSaveAddDialog()"
      @confirmDialog="addVehicle()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Edit Vehicle ---->

    <mini-dialog
      :dialog="saveEditDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeSaveEditDialog()"
      @confirmDialog="editVehicle()"
    ></mini-dialog>

    <!---- Here is the Confirmation of Deleting Employee/s ---->

    <mini-dialog
      :dialog="deleteDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      :cardTitle="'Delete'"
      :cardText="'Are you sure you want to delete this?'"
      @closeDialog="closeDeleteDialog()"
      @confirmDialog="deleteVehicle()"
    ></mini-dialog>

    <!---- Here is the Dialog for Vehicle Status Settings ------>

    <!---- Here is the Dialog for Vehicle Status Settings ------>

    <full-width-dialog
      :title="'Vehicle Status'"
      :color="'primary'"
      :dialog="settingsDialog"
      :dialogLoading="fullWidthDialogLoading"
      :dialogLoadingColor="'primary'"
      :saveButton="true"
      dark
      @saveDialog="openConfirmStatusDialog()"
      @closeDialog="closeStatusDialog()"
    >
      <v-container fluid>
        <v-container v-if="settingsDialogLoading" style="text-align: center;">
          <v-row>
            <v-col cols="12">
              <v-progress-circular
                :size="50"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-container>
        <v-row v-else>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-row>
              <v-col cols="12" sm="12" md="2" lg="2" xl="2">
                <v-list subheader>
                  <v-list-item>
                    <v-list-item-action>
                      <v-switch
                        v-model="decommissioned"
                        :disabled="!$moduleAccess('settings')"
                        color="success"
                      ></v-switch>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>Decommissioned</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-action>
                      <v-switch
                        v-model="gps_for_service"
                        :disabled="!$moduleAccess('settings')"
                        color="success"
                      ></v-switch>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>GPS For Service</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                <v-textarea
                  v-model="vehicle_status_remarks"
                  :readonly="!$moduleAccess('settings')"
                  label="Remarks"
                  rows="4"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-data-table
              :headers="remarksHeaders"
              :items="vehicle_status_remarks_array"
              class="elevation-1 scrolling-wrapper-two"
              dense
              disable-pagination
              hide-default-footer
              :server-items-length="vehicle_status_remarks_array.length"
            >
              <template
                v-if="vehicle_status_remarks_array.length === 0"
                v-slot:no-data
              >
                <v-container>
                  <v-row>
                    <v-col md="6" offset-md="3">
                      <span class="title font-weight-bold"
                        >NO DATA AVAILABLE.</span
                      >
                    </v-col>
                  </v-row>
                </v-container>
              </template>
              <template v-else v-slot:body="{ items }">
                <tbody>
                  <tr v-for="(item, index) in items" :key="index">
                    <td class="text-center">
                      {{ index + queryParams.offset + 1 }}
                    </td>
                    <td class="text-center">
                      {{ $dateFormat(item.created_at, "YYYY-MM-DD") }}
                    </td>
                    <td class="text-uppercase">
                      {{ item.status ? item.status : "--" }}
                    </td>
                    <td class="text-uppercase">{{ item.remarks }}</td>
                    <td class="text-uppercase">{{ item.created_by }}</td>
                    <td class="text-center">
                      {{ $dateFormat(item.created_at, "HH:mm:ss") }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-data-table>
            <!-- <v-card>
              <v-card-text class="scrolling-wrapper-two">
                <h1>Remarks</h1>
                <hr style="margin: 10px 0;" />
                <ul>
                  <li
                    v-for="(item, i) in vehicle_status_remarks_array"
                    :key="i"
                    @mouseover="remarkHoverIn(i)"
                    @mouseleave="remarkHoverOut(i)"
                    style="font-size: 18px; margin: 10px 0;"
                  >
                    {{ item.remarks }}
                    <v-tooltip
                      v-if="showDeleteRemarkButton && i == remarkIndex"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          @click="openDeleteRemarkDialog(item)"
                          icon
                          class="ml-5"
                          v-bind="attrs"
                          v-on="on"
                          ><v-icon color="red">mdi-close</v-icon></v-btn
                        >
                      </template>
                      <span>Remove</span>
                    </v-tooltip>
                  </li>
                </ul>
              </v-card-text>
            </v-card> -->
          </v-col>
        </v-row>
      </v-container>
    </full-width-dialog>

    <!-- <mini-dialog
      :dialog="settingsDialog"
      :max-width="'50%'"
      @closeDialog="closeStatusDialog()"
      @confirmDialog="openConfirmStatusDialog()"
    >
      <v-card-title class="pl-3 gradientColor">
        <span class="headline font-weight-black white--text"
          >Vehicle Status</span
        >
      </v-card-title>

      <v-divider></v-divider>

      <v-card-text class="px-0">
        <v-container style="padding: 0 30px 0 30px;" class="py-3">
          <v-row>
            <v-container
              v-if="settingsDialogLoading"
              style="text-align: center;"
            >
              <v-row>
                <v-col cols="12">
                  <v-progress-circular
                    :size="50"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
            <v-col v-else cols="12" sm="12" md="12" lg="12" xl="12">
              <v-list subheader>
                <v-row>
                  <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                    <v-list-item>
                      <v-list-item-action>
                        <v-switch
                          v-model="decommissioned"
                          :disabled="!$moduleAccess('settings')"
                          color="success"
                        ></v-switch>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>Decommissioned</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-action>
                        <v-switch
                          v-model="gps_for_service"
                          :disabled="!$moduleAccess('settings')"
                          color="success"
                        ></v-switch>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>GPS For Service</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-col>
                </v-row>
              </v-list>
              <v-textarea
                v-model="vehicle_status_remarks"
                :readonly="!$moduleAccess('settings')"
                label="Remarks"
                outlined
              ></v-textarea> </v-col
          ></v-row>
        </v-container>
      </v-card-text>

      <v-divider></v-divider>
    </mini-dialog> -->

    <!---- Here is the Confirmation of Vehicle Status ---->

    <mini-dialog
      :dialog="confirmStatusDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeConfirmStatusDialog()"
      @confirmDialog="updateStatus()"
    ></mini-dialog>

    <!---- Here is the Assign GPS List ----->

    <v-dialog v-model="assignGPSDialog" scrollable persistent max-width="300px">
      <v-card>
        <v-card-title>Select GPS</v-card-title>
        <v-divider></v-divider>
        <v-container v-if="assignGPSDialogLoading">
          <v-row>
            <v-col cols="4"></v-col>
            <v-col cols="4" style="padding-left: 20px; padding-right: 20px;">
              <v-progress-circular
                :width="3"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>
        </v-container>
        <v-card-text v-else style="height: 300px;">
          <v-radio-group v-model="assignGPSData" column>
            <v-radio
              v-for="(item, i) in gpsDevices"
              :key="i"
              :label="item.text"
              :value="item.value"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="closeAssignGPSDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn color="green" text @click="openConfirmAddGPSDialog()">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!---- Here is the Confirmation of Vehicle Status ---->

    <mini-dialog
      :dialog="confirmAssignGPSDialog"
      :max-width="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeConfirmAddGPSDialog()"
      @confirmDialog="assignGPS()"
    ></mini-dialog>

    <!---- Here is the Dialog for View Images ------>

    <v-dialog v-model="imagesDialog" persistent max-width="1200">
      <v-card>
        <v-card-title class="pl-3" style="background-color: #2196F3;">
          <span class="headline font-weight-black white--text"
            >View Images</span
          >
          <v-spacer></v-spacer>
          <v-btn @click="openConfirmUploadDialog()" icon>
            <v-icon class="white--text">mdi-content-save</v-icon>
          </v-btn>
          &nbsp;
          <v-btn @click="closeImages()" icon>
            <v-icon class="white--text">mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text class="px-0">
          <v-container style="text-align: center; white-space: nowrap;">
            <v-row no-gutters>
              <v-col
                v-for="(item, i) in images"
                :key="i"
                cols="12"
                sm="12"
                md="3"
                lg="3"
                xl="3"
                style="margin: 0 10px 10px 10px;"
              >
                <div
                  v-for="(obj, e) in item.images"
                  :key="e"
                  class="imageClass"
                  :style="`background-image: url('${obj.image}');`"
                ></div>
              </v-col>
              <v-col
                style="padding: 50px;"
                cols="12"
                sm="12"
                md="3"
                lg="3"
                xl="3"
              >
                <input
                  style="display: none;"
                  multiple
                  type="file"
                  id="myFile"
                  name="filename"
                  accept="image/*"
                  @change="onFileChange"
                />

                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <label
                      for="myFile"
                      class="fab-button ripple"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                    </label>
                  </template>
                  <span>Choose Image/s</span>
                </v-tooltip>

                <br />
                <span v-if="imagesToUpload.length > 0"
                  >Uploaded
                  {{
                    imagesToUpload.length > 1
                      ? imagesToUpload.length + " files..."
                      : imagesToUpload.length + " file"
                  }}</span
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>

    <mini-dialog
      :dialog="imagesUploadDialog"
      :maxWidth="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeUploadDialog"
      @confirmDialog="uploadImages()"
    ></mini-dialog>

    <!---- Here is the Dialog for Hide Vehicles From Company ------>

    <v-dialog v-model="hideVehiclesFromCompany" scrollable max-width="800px">
      <v-card>
        <v-card-title>Select Vehicle/s</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px;">
          <v-data-table
            v-model="vehiclesSelectedToHide"
            :headers="hideVehiclesFromCompanyHeaders"
            :items="itemsVehicle"
            disable-pagination
            hide-default-footer
            show-select
            :server-items-length="items.length"
          >
            <template v-if="items.length === 0" v-slot:no-data>
              <v-container>
                <v-row>
                  <v-col md="6" offset-md="3">
                    <span class="title font-weight-bold"
                      >NO DATA AVAILABLE.</span
                    >
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template v-else v-slot:body="{ items }">
              <tbody>
                <tr v-for="(item, index) in items" :key="index">
                  <td class="text-no-wrap text-center">
                    <v-checkbox
                      v-model="vehiclesSelectedToHide"
                      class="mt-0 pt-0"
                      color="primary"
                      hide-details
                      :value="item"
                    ></v-checkbox>
                  </td>
                  <td class="text-center">
                    {{ index + 1 }}
                  </td>
                  <td class="text-uppercase">{{ item.plate_no }}</td>
                  <td class="text-uppercase">{{ item.vehicle_no }}</td>
                  <td class="text-uppercase">
                    {{ item.gps_device ? item.gps_device.imei_no : "--" }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="closeHideVehiclesFromCompanyDialog()"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="openConfirmHideVehiclesFromCompanyDialog()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <mini-dialog
      :dialog="confirmHideVehiclesFromCompanyDialog"
      :maxWidth="'400'"
      :confirmSave="true"
      :loading="confirmLoading"
      @closeDialog="closeConfirmHideVehiclesFromCompanyDialog()"
      @confirmDialog="saveHideVehiclesFromCompany()"
    ></mini-dialog>
  </v-container>
</template>

<script>
import Services from "@/services/SubCompanyServices";
import CompanyServices from "@/services/CompanyServices";
import DetailsContainer from "@/components/details_container/VehicleDetailsContainer";

export default {
  components: {
    DetailsContainer,
  },
  data: () => {
    return {
      title: "Vehicle",
      headers: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Plate No", sortable: false },
        { text: "Vehicle No", sortable: false },
        { text: "GPS Device", sortable: false },
        { text: "Action", align: "center", sortable: false },
      ],
      items: [],
      data: {},
      queryParams: {
        search: "",
        limit: 10,
        offset: 0,
      },
      totalItems: 0,
      itemFrom: 0,
      itemTo: 0,
      disabledNext: false,
      disabledBack: true,
      disabledLimit: true,
      page: [
        { text: 10, value: 10 },
        { text: 20, value: 20 },
        { text: 30, value: 30 },
        { text: 40, value: 40 },
        { text: 50, value: 50 },
        { text: 60, value: 60 },
        { text: 70, value: 70 },
        { text: 80, value: 80 },
        { text: 90, value: 90 },
        { text: 100, value: 100 },
      ],
      confirmLoading: false,
      fullWidthDialog: false,
      fullWidthDialogLoading: false,
      dialogType: "",
      saveButton: false,
      readonly: false,
      saveAddDialog: false,
      saveEditDialog: false,
      deleteDialog: false,
      gpsDevices: [],
      itemsVehicle: [],
      settingsDialog: false,
      settingsDialogLoading: false,
      confirmStatusDialog: false,
      assignGPSDialog: false,
      assignGPSDialogLoading: false,
      confirmAssignGPSDialog: false,
      assignGPSData: null,
      decommissioned: false,
      gps_for_service: false,
      vehicle_status_remarks: "",
      imagesDialog: false,
      images: [],
      imagesToUpload: [],
      imagesUploadDialog: false,
      imagesQueryParams: {},
      hideVehiclesFromCompany: false,
      vehiclesSelectedToHide: [],
      hideVehiclesFromCompanyHeaders: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Plate No", sortable: false },
        { text: "Vehicle No", sortable: false },
        { text: "GPS Device", sortable: false },
      ],
      confirmHideVehiclesFromCompanyDialog: false,
      vehicle_status_remarks_array: [],
      showDeleteRemarkButton: false,
      remarkIndex: 0,
      deleteRemarkDialog: false,
      remarksHeaders: [
        {
          text: "#",
          sortable: false,
          align: "center",
        },
        { text: "Date", sortable: false, align: "center" },
        { text: "Vehicle Status", sortable: false },
        { text: "Remarks", sortable: false },
        { text: "Created By", sortable: false },
        { text: "Time", sortable: false, align: "center" },
      ],
    };
  },
  created: function() {
    if (this.$store.state.token !== null) {
      this.getData();
    } else {
      this.$router.push({
        path: "/",
      });

      this.$store.dispatch("clearStore");
    }
  },
  computed: {
    pageLimit: {
      get() {
        return 10;
      },
      set(val) {
        this.queryParams.limit = val;
        this.queryParams.offset = 0;
      },
    },
  },
  methods: {
    async search(data) {
      this.queryParams.search = data;
      await this.getData();
    },
    async getData() {
      this.$store.dispatch("startLoading");

      this.queryParams.tracking_admin_user = this.$store.state.employee.enable_mark_logo;

      this.queryParams.company_id = this.$store.state.company.id;

      this.queryParams.sub_company_id = this.$store.state.subcompany.id;

      try {
        let response = (await Services.getVehicles(this.queryParams)).data;

        this.items = response.data;

        // Assign Total number of data
        this.totalItems = response.count;

        // Assign Number started the data
        this.itemFrom = this.totalItems > 0 ? this.queryParams.offset + 1 : 0;

        // Assign Number end the data
        this.itemTo = this.queryParams.offset + this.queryParams.limit;

        if (this.totalItems <= this.itemTo) {
          this.itemTo = this.totalItems;
        }

        // Conditions for NextPage Button
        if (this.itemTo === this.totalItems) {
          this.disabledNext = true;
        } else {
          this.disabledNext = false;
        }
        // Conditions for BackPage Button
        if (this.itemFrom > 1) {
          this.disabledBack = false;
        } else {
          this.disabledBack = true;
        }

        if (this.totalItems <= this.queryParams.limit) {
          this.disabledLimit = true;
        } else {
          this.disabledLimit = false;
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    nextClick() {
      this.queryParams.offset += this.queryParams.limit;
      this.getData();
    },
    backClick() {
      this.queryParams.offset -= this.queryParams.limit;
      this.getData();
    },
    limitClick() {
      this.queryParams.offset = 0;
      this.getData();
    },
    async openStatusDialog(data) {
      this.settingsDialog = true;

      this.settingsDialogLoading = true;

      this.data.id = data.id;

      const getData = await Services.getVehicleStatus(data.id);

      if (getData.data) {
        const status = getData.data;

        this.decommissioned = status.decommissioned;
        this.gps_for_service = status.gps_for_service;
        this.vehicle_status_remarks_array = status.vehicle_status_remarks;
        this.vehicle_status_remarks = "";
      }

      setTimeout(() => {
        this.settingsDialogLoading = false;
      }, 60);
    },
    closeStatusDialog() {
      this.settingsDialog = false;
    },
    openConfirmStatusDialog() {
      this.confirmStatusDialog = true;
    },
    closeConfirmStatusDialog() {
      this.confirmStatusDialog = false;
    },
    async updateStatus() {
      this.confirmLoading = true;

      const body = {
        decommissioned: this.decommissioned,
        gps_for_service: this.gps_for_service,
        vehicle_status_remarks: this.vehicle_status_remarks,
      };

      try {
        const response = await Services.updateStatus(this.data.id, body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: "Vehicle status has been updated!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    async openAssignGPSDialog(data) {
      this.assignGPSDialog = true;

      this.assignGPSDialogLoading = true;

      this.data.id = data.id;

      this.gpsDevices = await this.setGPSDevices();

      setTimeout(() => {
        this.assignGPSDialogLoading = false;
      }, 60);
    },
    closeAssignGPSDialog() {
      this.assignGPSDialog = false;
    },
    openConfirmAddGPSDialog() {
      if (this.assignGPSData) {
        this.confirmAssignGPSDialog = true;
      } else {
        this.$infoBar({
          type: "error",
          text: "Select GPS first!",
        });
      }
    },
    closeConfirmAddGPSDialog() {
      this.confirmAssignGPSDialog = false;
    },
    async assignGPS() {
      this.confirmLoading = true;

      const body = {
        tracking_gps_device_id: this.assignGPSData,
      };

      try {
        const response = await Services.assignGPS(this.data.id, body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: "GPS has been assigned!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    async openHideVehiclesFromCompanyDialog() {
      this.hideVehiclesFromCompany = true;

      const gpsVehicle = this.items.filter((data) => {
        return data.gps_device;
      });

      this.itemsVehicle = gpsVehicle.filter((item) => {
        return !item.gps_device.companyId;
      });
    },
    closeHideVehiclesFromCompanyDialog() {
      this.hideVehiclesFromCompany = false;
    },
    openConfirmHideVehiclesFromCompanyDialog() {
      this.confirmHideVehiclesFromCompanyDialog = true;
    },
    closeConfirmHideVehiclesFromCompanyDialog() {
      this.confirmHideVehiclesFromCompanyDialog = false;
    },
    async saveHideVehiclesFromCompany() {
      this.confirmLoading = true;

      const body = {
        sub_company_id: this.$store.state.subcompany.id,
        vehicles_to_hide: this.vehiclesSelectedToHide,
      };

      try {
        const response = await Services.hideVehiclesFromCompany(body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeHideVehiclesFromCompanyDialog();
          this.closeConfirmHideVehiclesFromCompanyDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: "New Configuration set successfully!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    async openAddDialog() {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "Add";
      this.saveButton = true;
      this.readonly = false;

      this.gpsDevices = await this.setGPSDevices();

      this.data.vehicle_no = "";
      this.data.plate_no = "";
      this.data.tracking_gps_device_id = null;
      this.data.speed_limit = 0;
      this.data.idle_time_limit = 0;
      this.data.pitstop_time_limit = 0;
      this.data.enable_smr = false;
      this.data.type_of_vehicle = "";

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeAddDialog() {
      this.fullWidthDialog = false;
    },
    validateAddDialog() {
      this.$refs.detailsContainer.validateForm();
    },
    openSaveAddDialog() {
      this.saveAddDialog = true;
    },
    closeSaveAddDialog() {
      this.saveAddDialog = false;
    },
    async addVehicle() {
      this.confirmLoading = true;

      const data = this.$refs.detailsContainer.computedData;

      const body = {
        vehicle_no: data.vehicle_no,
        enable_smr: data.enable_smr,
        tracking_gps_device_id: data.tracking_gps_device_id,
        plate_no: data.plate_no,
        speed_limit: data.speed_limit,
        idle_time_limit: data.idle_time_limit,
        pitstop_time_limit: data.pitstop_time_limit,
        type_of_vehicle: data.type_of_vehicle,
        // company_id: data.company_id,
        sub_company_id: this.$store.state.subcompany.id,
      };

      try {
        const response = await Services.addVehicle(body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been added!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async openViewDialog(item) {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "View";
      this.saveButton = false;
      this.readonly = true;

      const returnedVehicle = await this.setVehicle(item.id);

      this.gpsDevices = await this.setGPSDevices();

      this.data.id = item.id;
      this.data.vehicle_no = returnedVehicle.vehicle_no;
      this.data.tracking_gps_device_id = returnedVehicle.tracking_gps_device_id;
      this.data.plate_no = returnedVehicle.plate_no;
      this.data.enable_smr = returnedVehicle.enable_smr;
      this.data.speed_limit = returnedVehicle.vehicle_settings
        ? returnedVehicle.vehicle_settings.speed_limit
        : 0;
      this.data.idle_time_limit = returnedVehicle.vehicle_settings
        ? returnedVehicle.vehicle_settings.idle_time_limit
        : 0;
      this.data.pitstop_time_limit = returnedVehicle.vehicle_settings
        ? returnedVehicle.vehicle_settings.pitstop_time_limit
        : 0;
      this.data.type_of_vehicle = returnedVehicle.type_of_vehicle;

      this.data.created_at = item.created_at;
      this.data.updated_at = item.updated_at;

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeViewDialog() {
      this.fullWidthDialog = false;
    },
    async openEditDialog(item) {
      this.fullWidthDialog = true;
      this.fullWidthDialogLoading = true;
      this.dialogType = "Edit";
      this.saveButton = true;
      this.readonly = false;

      const returnedVehicle = await this.setVehicle(item.id);

      this.gpsDevices = await this.setGPSDevices();

      this.data.id = item.id;
      this.data.vehicle_no = returnedVehicle.vehicle_no;
      this.data.plate_no = returnedVehicle.plate_no;
      this.data.enable_smr = returnedVehicle.enable_smr;
      this.data.speed_limit = returnedVehicle.vehicle_settings
        ? returnedVehicle.vehicle_settings.speed_limit
        : 0;
      this.data.idle_time_limit = returnedVehicle.vehicle_settings
        ? returnedVehicle.vehicle_settings.idle_time_limit
        : 0;
      this.data.pitstop_time_limit = returnedVehicle.vehicle_settings
        ? returnedVehicle.vehicle_settings.pitstop_time_limit
        : 0;
      this.data.type_of_vehicle = returnedVehicle.type_of_vehicle;

      setTimeout(() => {
        this.fullWidthDialogLoading = false;
      }, 600);
    },
    closeEditDialog() {
      this.fullWidthDialog = false;
    },
    validateEditDialog() {
      this.$refs.detailsContainer.validateForm();
    },
    openSaveEditDialog() {
      this.saveEditDialog = true;
    },
    closeSaveEditDialog() {
      this.saveEditDialog = false;
    },
    async editVehicle() {
      this.confirmLoading = true;

      const data = this.$refs.detailsContainer.computedData;

      const body = {
        vehicle_no: data.vehicle_no,
        enable_smr: data.enable_smr,
        plate_no: data.plate_no,
        speed_limit: data.speed_limit,
        idle_time_limit: data.idle_time_limit,
        pitstop_time_limit: data.pitstop_time_limit,
        type_of_vehicle: data.type_of_vehicle,
      };

      try {
        const response = await Services.editVehicle(this.data.id, body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been edited!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }

      this.confirmLoading = await false;
    },
    openDeleteDialog(item) {
      this.deleteDialog = true;
      this.data.id = item.id;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    async deleteVehicle() {
      this.confirmLoading = true;

      try {
        const response = await Services.deleteVehicle(this.data.id);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          this.closeAllDialog();
          await this.getData();
          this.$infoBar({
            type: "success",
            text: this.title + " has been deleted!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: this.titleCase(error.response.data.message) + "!",
          });
        }
      }
      this.confirmLoading = await false;
    },
    async setVehicle(id) {
      const response = await CompanyServices.getVehicle(id);

      return response.data;
    },
    async setGPSDevices() {
      // const response = await Services.getGPSDevices({
      const response = await CompanyServices.getGPSDevices({
        // sub_company_id: this.$store.state.subcompany.id,
        company_id: this.$store.state.company.id,
        dialog_type: this.dialogType,
      });

      const returnedData = response.data.data.map((item) => {
        return {
          text: item.imei_no.toUpperCase(),
          value: item.id,
        };
      });

      return returnedData;
    },
    async openImages() {
      this.imagesDialog = true;
      await this.getImages();
    },
    async onFileChange(e) {
      const files = e.target.files || e.dataTransfer.files;

      files.forEach((item) => {
        const reader = new FileReader();
        reader.readAsDataURL(item);
        reader.onload = (e) => {
          const image = e.target.result;

          this.imagesToUpload.push({
            image: image,
          });
        };
      });
    },
    closeImages() {
      this.images.length = 0;
      this.imagesToUpload.length = 0;
      this.imagesDialog = false;
    },
    openConfirmUploadDialog() {
      this.imagesUploadDialog = true;
    },
    async uploadImages() {
      this.confirmLoading = true;

      try {
        const body = {
          company_id: this.$store.state.company.id,
          vehicle_id: this.data.id,
          uploaded_images: this.imagesToUpload,
        };

        const response = await Services.uploadImages(body);

        if (
          response.status === 201 ||
          response.status === 204 ||
          response.status === 200
        ) {
          await this.closeUploadDialog();
          await this.getImages();
          this.$infoBar({
            type: "success",
            text: "Image/Images has been added!",
          });
        } else {
          this.$infoBar({
            type: "error",
            text: "Server Error!",
          });
        }
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network connection",
          });
        }
      }

      this.confirmLoading = await false;
    },
    closeUploadDialog() {
      this.images.length = 0;
      this.imagesToUpload.length = 0;
      this.imagesUploadDialog = false;
    },
    async getImages() {
      this.imagesQueryParams.company_id = this.$store.state.company.id;

      this.imagesQueryParams.vehicle_id = this.data.id;

      try {
        let response = (await Services.getImages(this.imagesQueryParams)).data;

        this.images = response.data;
      } catch (error) {
        if (!error.response) {
          this.$infoBar({
            type: "error",
            text: "No network or server connection",
          });
        }
      }

      this.$store.dispatch("endLoading");
    },
    closeAllDialog() {
      this.closeAddDialog();
      this.closeSaveAddDialog();
      this.closeViewDialog();
      this.closeEditDialog();
      this.closeSaveEditDialog();
      this.closeDeleteDialog();
      this.closeStatusDialog();
      this.closeConfirmStatusDialog();
      this.closeAssignGPSDialog();
      this.closeConfirmAddGPSDialog();
    },
    titleCase(str) {
      let splitStr = str.toLowerCase().split(" ");
      for (let i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] =
          splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(" ");
    },
  },
};
</script>

<style scoped>
.gradientColor {
  background-color: #990000;
  background-image: linear-gradient(147deg, #990000 0%, #ff0000 74%);
}

/* Ripple effect */
.ripple {
  background-position: center;
  transition: background 0.8s;
}
.ripple:hover {
  background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%)
    center/15000%;
}
.ripple:active {
  background-color: #6eb9f7;
  background-size: 100%;
  transition: background 0s;
}

.fab-button {
  border: none;
  padding: 18px 18px;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  background-color: #2196f3;
  box-shadow: 0 0 4px #999;
  outline: none;
  border-radius: 100%;
  display: inline-block;
  margin-bottom: 10px;
}

.imageClass {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 200px;
}
</style>
