<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title style="background-color: #EEEEEE;"
            >Vehicle Details</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="formVehicle">
              <v-row>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="data.vehicle_no"
                    label="Vehicle No"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="data.plate_no"
                    label="Plate No"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="data.franchise_no"
                    label="Franchise No"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="data.chassis_no"
                    label="Chassis No"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="data.route_no"
                    label="Route"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="data.sim_no"
                    label="Sim No"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-select
                    v-model="data.type_of_vehicle"
                    label="Vehicle Type"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                    :items="vehicleTypes"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-autocomplete
                    v-if="dialogType !== 'Edit'"
                    v-model="data.tracking_gps_device_id"
                    label="GPS Device"
                    :readonly="readonly"
                    :items="gpsDevices"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3"
                  ><v-text-field
                    v-model="data.speed_limit"
                    label="Speed Limit (kph)"
                    type="number"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                  ></v-text-field
                ></v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="data.pitstop_time_limit"
                    label="Pitstop Time Limit (mins)"
                    type="number"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                  ></v-text-field
                ></v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-text-field
                    v-model="data.idle_time_limit"
                    label="Idle Time Limit (mins)"
                    type="number"
                    required
                    :rules="[rules.required]"
                    :readonly="readonly"
                  ></v-text-field
                ></v-col>
                <v-col cols="12" sm="12" md="3" lg="3" xl="3">
                  <v-menu
                    v-if="!readonly"
                    v-model="menuEnrollmentDate"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="data.enrollment_date"
                        label="Contract Date"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="data.enrollment_date"
                      @input="menuEnrollmentDate = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-row v-if="readonly">
                <!-- <v-col cols="12" sm="12" md="1" lg="1" xl="1"></v-col> -->
                <v-col
                  cols="12"
                  sm="12"
                  md="5"
                  lg="5"
                  xl="5"
                  style="margin-left:7px"
                >
                  <span class="subtitle-1 font-weight-bold">Created At: </span
                  ><span class="subtitle-1">{{ data.created_at }}</span
                  ><br />
                  <br />
                  <span class="subtitle-1 font-weight-bold">Modified At: </span
                  ><span class="subtitle-1">{{ data.updated_at }}</span>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- <v-row v-if="data.enable_smr || !readonly">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-card>
          <v-card-title style="background-color: #EEEEEE;"
            >SMR Access</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                <v-switch
                  v-model="enable_smr"
                  label="Enable SMR"
                  :readonly="readonly"
                ></v-switch>
              </v-col>
              <v-col
                v-if="enable_smr"
                cols="12"
                sm="12"
                md="7"
                lg="7"
                xl="7"
                style="padding:0% 0%;"
              >
                <v-row style="margin-top: 0%">
                  <v-col
                    cols="12"
                    sm="12"
                    md="7"
                    lg="7"
                    xl="7"
                    style="margin-top:0%;"
                  >
                    <v-row>
                      <v-col cols="3" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model="data.smr_hr_reading"
                          label="Hours"
                          oninput="javascript: 
                    if (this.value.length > this.maxLength)
                     this.value = this.value.slice(0, this.maxLength);"
                          type="number"
                          maxlength="3"
                          required
                          :rules="[rules.required]"
                          :readonly="readonly"
                        ></v-text-field> </v-col
                      ><label
                        style="font-weight:bolder; 
                    font-size:20px;
                    margin-top:9%"
                        >:</label
                      >
                      <v-col cols="3" sm="3" md="3" lg="3" xl="3">
                        <v-text-field
                          v-model="data.smr_mn_reading"
                          label="Minutes"
                          oninput="javascript: 
                    if (this.value.length > this.maxLength)
                     this.value = this.value.slice(0, this.maxLength);"
                          type="number"
                          maxlength="2"
                          required
                          :rules="[rules.required, rules.maxNum]"
                          :readonly="readonly"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="4"
                    lg="4"
                    xl="4"
                    style="margin-top: 0%"
                  >
                    <v-text-field
                      v-model="data.total_elapse_time"
                      label="Total Elapse Time"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->

    <v-row v-if="data.enable_temp_sensor || !readonly">
      <v-col cols="12" sm="12" md="12" lg="12" xl="12">
        <v-card>
          <v-card-title style="background-color: #EEEEEE;"
            >Temperature Sensor</v-card-title
          >
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="5" lg="5" xl="5">
                <v-switch
                  v-model="enable_temp_sensor"
                  label="Enable Temperature Sensor"
                  :readonly="readonly"
                ></v-switch>
              </v-col>
              <v-col
                v-if="enable_temp_sensor"
                cols="12"
                sm="12"
                md="7"
                lg="7"
                xl="7"
                style="padding:0% 0%;"
              >
                <v-row style="margin-top: 0%">
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                      v-model="data.temp_low_limit"
                      label="Temperature Low Limit"
                      type="number"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="4" lg="4" xl="4">
                    <v-text-field
                      v-model="data.temp_high_limit"
                      label="Temperature High Limit"
                      type="number"
                      required
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      },
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "",
    },
    vendors: {
      type: Array,
      default: () => {
        return [];
      },
    },
    gpsDevices: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data: () => {
    return {
      rules: {
        required: (value) => !!value || "This field is required.",
        maxNum: (value) => value <= 59 || "Value must be 59 or Below.",
      },
      enable_smr: null,
      enable_temp_sensor: null,
      menuEnrollmentDate: false,
      vehicleTypes: [
        "Default",
        "Bus",
        "Truck/Close Van",
        "Sedan",
        "Jeep",
        "Van",
        "Trailer Truck",
        "Cargo Ship",
      ],
    };
  },
  created: function() {
    this.enable_smr = this.data.enable_smr;
    this.enable_temp_sensor = this.data.enable_temp_sensor;
  },
  computed: {
    computedData() {
      return {
        vehicle_no: this.data.vehicle_no,
        franchise_no: this.data.franchise_no,
        chassis_no: this.data.chassis_no,
        route_no: this.data.route_no,
        sim_no: this.data.sim_no,
        enable_smr: this.enable_smr,
        enable_temp_sensor: this.enable_temp_sensor,
        tracking_gps_device_id: this.data.tracking_gps_device_id,
        plate_no: this.data.plate_no,
        company_id: this.$store.state.company.id,
        speed_limit: this.data.speed_limit,
        idle_time_limit: this.data.idle_time_limit,
        pitstop_time_limit: this.data.pitstop_time_limit,
        enrollment_date: this.data.enrollment_date,
        type_of_vehicle: this.data.type_of_vehicle,
        // smr_hr_reading: this.data.smr_hr_reading,
        // smr_mn_reading: this.data.smr_mn_reading,
        //////////////// start_ss_smr: this.data.start_ss_smr,
        // total_elapse_time: this.data.total_elapse_time,
        temp_low_limit: this.enable_temp_sensor ? this.data.temp_low_limit : 0,
        temp_high_limit: this.enable_temp_sensor
          ? this.data.temp_high_limit
          : 0,
      };
    },
  },
  methods: {
    validateForm() {
      if (this.$refs.formVehicle.validate()) {
        this.$emit("openSaveDialog");
      }
    },

    openImages() {
      this.$emit("openImages");
    },
  },
  // watch:{
  //   enable_smr:async function(){
  //     if(this.enable_smr){
  //       console.log(this.data.start_smr)
  //     }
  //   }
  // }
};
</script>

<style scoped>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="time"] {
  /* border: 5px solid #b71c1c; */
  color: #000000;
  font-size: 14px;
  font-family: helvetica;
  width: 100%;
}

input[type="time"]:focus {
  outline: none;
}

/* Wrapper around the hour, minute, second, and am/pm fields as well as 
the up and down buttons and the 'X' button */
input[type="time"]::-webkit-datetime-edit-fields-wrapper {
  display: flex;
}

/* The space between the fields - between hour and minute, the minute and 
second, second and am/pm */

input[type="time"]::-webkit-datetime-edit-text {
  padding: 15px 4px;
}

/* The naming convention for the hour, minute, second, and am/pm field is
`-webkit-datetime-edit-{field}-field` */

/* Hour */
input[type="time"]::-webkit-datetime-edit-hour-field {
  background-color: #f2f4f5;
  /* border-radius: 15%; */
  padding: 12px 9px;
}

/* Minute */
input[type="time"]::-webkit-datetime-edit-minute-field {
  background-color: #f2f4f5;
  /* border-radius: 15%; */
  padding: 12px 9px;
}

/* Second */
input[type="time"]::-webkit-datetime-edit-second-field {
  background-color: #f2f4f5;
  /* border-radius: 15%; */
  padding: 12px 9px;
}

/* AM/PM */
input[type="time"]::-webkit-datetime-edit-ampm-field {
  /* background-color: #ffc107; */
  background-color: #f2f4f5;
  border-radius: 15%;
  padding: 12px 9px;
}

/* 'X' button for resetting/clearing time */
/* input[type="time"]::-webkit-clear-button {
  display: none;
} */

/* Up/Down arrows for incrementing/decrementing the value */
/* input[type="time"]::-webkit-inner-spin-button {
  display: none;
 
} */

input[type="time"]::-webkit-datetime-edit-ampm-field {
  display: none;
}

input[type="time"]::-webkit-clear-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  -ms-appearance: none;
  appearance: none;
  margin: -10px;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
}
</style>
